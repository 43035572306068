import React, { Component } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import Moment from 'react-moment';
import Loading from "../Common/Loading";
import GetScoreByType from '../Helpers/GetScoreByType';
import './JourneyList.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useParams, useLocation } from 'react-router-dom';

export function JourneyListWrapperDebug(props) {
    const params = useParams();
    const location = useLocation();

    return <JourneyListDebug {...props} params={params} location={location} />;
}

export class JourneyListDebug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            journeyData: [],
            loading: true,
            error: false,
            startDate: null,
            endDate: null,
            pageSize: 30,
            pageNumber: 1,
            totalResults: 0,
            numberOfPages: 1,
            vehicleReference: this.props.params.vehiclereference,
            showPhantom: false,
            canLoadMore: true,
            selectedJourneyId: null,
            showMarkers: true,
            showEventMarkers: false,
            showSpeedingEventMarkers: false,
            showSearchContent: false,
            numberValue: 0,
            tempNumberValue: 0,
            debugMarkers: [],
            startEndMarkers: [],
            activeJourney: null,
            eventMarkers: [],
            speedingEventMarkers: [],
            debugPositionsGeoJSON: { type: 'FeatureCollection', features: [] }
        };
        document.title = "AGMT | " + this.state.vehicleReference + " Journeys";

        this.mapContainer = null;
        this.map = null;
        this.observer = null;
        this.observerRef = React.createRef();

        this.popup = new mapboxgl.Popup({closeButton: false, closeOnClick: false}); // single popup for hover

        this.toggleIsPhantom = this.toggleIsPhantom.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.getJourneys(this.state.pageSize, this.state.pageNumber);
    }

    componentDidMount() {
        mapboxgl.accessToken = this.props.mapBoxApiKey;

        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            zoom: 1,
        });

        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(new mapboxgl.ScaleControl());

        this.map.on('style.load', () => {
            // Add debug positions layer for GPS points
            if (!this.map.getSource('debugPositions')) {
                this.map.addSource('debugPositions', {
                    type: 'geojson',
                    data: this.state.debugPositionsGeoJSON
                });
                this.map.addLayer({
                    id: 'debug-positions-layer',
                    type: 'circle',
                    source: 'debugPositions',
                    layout: {
                        'visibility': this.state.showMarkers ? 'visible' : 'none'
                    },
                    paint: {
                        'circle-radius': 5,
                        'circle-color': [
                            'match',
                            ['get', 'markerColor'],
                            '#4CAF50', '#4CAF50',
                            '#FFEB3B', '#FFEB3B',
                            '#F44336', '#F44336',
                            '#4CAF50'
                        ]
                    }
                });
            }

            // Hover (mousemove) event for debug-positions-layer
            this.map.on('mousemove', 'debug-positions-layer', (e) => {
                if (e.features.length > 0) {
                    const feature = e.features[0];
                    const props = feature.properties;

                    const timestamp = new Date(props.timestamp).toLocaleString();
                    const speedValue = parseFloat(props.speed);
                    const hdopValue = parseFloat(props.hdop);
                    const speedConverted = (speedValue * (this.props.speedUnit === 0 ? 1 : 1.60934)).toFixed(0);

                    const tooltipContent = `
                        <div style="color: #333; font-size: 14px; padding:10px; background-color:#f9f9f9; 
                            border:1px solid #ddd;border-radius:4px;text-align:center;">
                            <p style="margin:0"><strong>Timestamp:</strong> ${timestamp}</p>
                            <p style="margin:0"><strong>Speed:</strong> ${speedConverted} ${this.props.speedUnit === 0 ? 'mph' : 'kmh'}</p>
                            <p style="margin:0"><strong>HDOP:</strong> ${hdopValue.toFixed(1)}</p>
                        </div>
                    `;
                    this.popup.setLngLat(e.lngLat).setHTML(tooltipContent).addTo(this.map);
                    this.map.getCanvas().style.cursor = 'pointer';
                }
            });

            this.map.on('mouseleave', 'debug-positions-layer', () => {
                this.popup.remove();
                this.map.getCanvas().style.cursor = '';
            });
        });

        // IntersectionObserver for infinite scrolling
        this.observer = new IntersectionObserver(
            entries => {
                const [entry] = entries;
                if (entry.isIntersecting && this.state.canLoadMore && !this.state.loading) {
                    this.loadMoreJourneys();
                }
            },
            { threshold: 1 }
        );
        if (this.observerRef.current) {
            this.observer.observe(this.observerRef.current);
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);

        this.state.debugMarkers.forEach((marker) => marker.remove());
        this.state.startEndMarkers.forEach((marker) => marker.remove());
        this.state.eventMarkers.forEach(m => m.remove());
        this.state.speedingEventMarkers.forEach(m => m.remove());

        if (this.observer && this.observerRef.current) {
            this.observer.unobserve(this.observerRef.current);
            this.observer.disconnect();
        }

        if (this.map) {
            this.map.remove();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Update debugPositions data
        if (this.map && this.map.getSource('debugPositions') && prevState.debugPositionsGeoJSON !== this.state.debugPositionsGeoJSON) {
            this.map.getSource('debugPositions').setData(this.state.debugPositionsGeoJSON);
        }

        // Update visibility of debug positions layer based on showMarkers
        if (this.map && this.map.getLayer('debug-positions-layer') && prevState.showMarkers !== this.state.showMarkers) {
            this.map.setLayoutProperty(
                'debug-positions-layer',
                'visibility',
                this.state.showMarkers ? 'visible' : 'none'
            );
        }

        // Toggle visibility of accelerometer (eventMarkers)
        if (prevState.showEventMarkers !== this.state.showEventMarkers) {
            if (this.state.showEventMarkers) {
                this.state.eventMarkers.forEach(marker => marker.getElement().style.display = 'block');
            } else {
                this.state.eventMarkers.forEach(marker => marker.getElement().style.display = 'none');
            }
        }

        // Toggle visibility of speeding (speedingEventMarkers)
        if (prevState.showSpeedingEventMarkers !== this.state.showSpeedingEventMarkers) {
            if (this.state.showSpeedingEventMarkers) {
                this.state.speedingEventMarkers.forEach(marker => marker.getElement().style.display = 'block');
            } else {
                this.state.speedingEventMarkers.forEach(marker => marker.getElement().style.display = 'none');
            }
        }
    }

    handleInputChange = (e) => {
        this.setState({ tempNumberValue: e.target.value });
    };

    handleButtonClick = () => {
        this.setState((prevState) => ({
            numberValue: prevState.tempNumberValue,
        }));
    };

    toggleIsPhantom = () => {
        this.setState(
            (prevState) => ({
                showPhantom: !prevState.showPhantom,
                journeyData: [],
                pageNumber: 1,
                canLoadMore: true
            }),
            () => {
                this.getJourneys(this.state.pageSize, this.state.pageNumber);
            }
        );
    };

    handleTableRowClick(journeyId) {
        this.fetchJourneyDetails(journeyId);
        this.setState({ activeJourney: journeyId });
    }

    handleScroll() {
        // Using IntersectionObserver, no scroll logic needed here
    }

    fetchJourneyDetails(journeyId) {
        const urlPath = `/api/Journey/journeyDebug/${journeyId}?minutesToAddOnDebug=${this.state.numberValue}`;

        fetch(urlPath, GetHttpConfig(this.props.userAccessToken))
            .then((response) => response.json())
            .then((data) => {
                const journeyData = this.state.journeyData.map((journey) => {
                    if (journey.journeyId === journeyId) {
                        return {
                            ...journey,
                            details: data.details,
                            accelerometerEvents: data.accelerometerEventPositions,
                            speedingEvents: data.speedingEvents,
                            debugpositions: data.debugPositions,
                        };
                    }
                    return journey;
                });

                // Remove old event markers
                this.state.eventMarkers.forEach(m => m.remove());
                this.state.speedingEventMarkers.forEach(m => m.remove());

                this.setState({
                    journeyData,
                    activeJourney: journeyId,
                    eventMarkers: [],
                    speedingEventMarkers: []
                });

                if (data.debugPositions) {
                    const debugFeatures = data.debugPositions.map(pos => {
                        let markerColor;
                        if (pos.hdop <= 2) {
                            markerColor = '#4CAF50';
                        } else if (pos.hdop <= 4) {
                            markerColor = '#FFEB3B';
                        } else {
                            markerColor = '#F44336';
                        }

                        return {
                            type: 'Feature',
                            geometry: { type: 'Point', coordinates: [pos.longitude, pos.latitude] },
                            properties: {
                                timestamp: pos.timestamp,
                                speed: pos.speed,
                                hdop: pos.hdop,
                                markerColor: markerColor
                            }
                        };
                    });

                    this.setState({ debugPositionsGeoJSON: { type: 'FeatureCollection', features: debugFeatures } });

                    this.addJourneyPathToMap(data.debugPositions);
                } else {
                    this.setState({ debugPositionsGeoJSON: { type: 'FeatureCollection', features: [] } });
                    console.error('No positions data found in the API response');
                }

                // Create accelerometer event markers
                if (data.accelerometerEventPositions) {
                    const eventMarkers = [];
                    data.accelerometerEventPositions.forEach((position) => {
                        const { latitude, longitude, accelerometerEvents } = position;
                        accelerometerEvents.forEach(event => {
                            const { level, eventType } = event;

                            let markerText = '';
                            if (eventType === 1) markerText = `A${level + 1}`;
                            else if (eventType === 2) markerText = `B${level + 1}`;
                            else if (eventType === 3) markerText = `C${level + 1}`;
                            else if (eventType === 4) markerText = `T`;
                            else if (eventType === 5) markerText = `M${level + 1}`;

                            let markerColor = '';
                            let textColor = '';
                            if (level === 0) {
                                markerColor = 'yellow';
                                textColor = 'black';
                            } else if (level === 1) {
                                markerColor = 'orange';
                                textColor = 'black';
                            } else if (level === 2) {
                                markerColor = 'darkorange';
                                textColor = 'white';
                            } else if (level === 3) {
                                markerColor = 'red';
                                textColor = 'white';
                            }

                            let eventTypeDesc = 'Unknown';
                            if (eventType === 1) eventTypeDesc = 'Acceleration';
                            else if (eventType === 2) eventTypeDesc = 'Braking';
                            else if (eventType === 3) eventTypeDesc = 'Cornering';
                            else if (eventType === 4) eventTypeDesc = 'Tamper';
                            else if (eventType === 5) eventTypeDesc = 'Maneuvering';

                            let levelDesc = '';
                            if (level === 0) levelDesc = 'Minor';
                            else if (level === 1) levelDesc = 'Medium';
                            else if (level === 2) levelDesc = 'Harsh';
                            else if (level === 3) levelDesc = 'Extreme';

                            const eventTime = new Date(event.eventDateTime).toLocaleTimeString();
                            const tooltipContent = `
                                <div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
                                    <p style="margin: 0">${eventTime}</p>
                                    <p style="margin: 0"><strong>Event Type:</strong> ${eventTypeDesc}</p>
                                    <p style="margin: 0"><strong>Level:</strong> ${level + 1} - ${levelDesc}</p>
                                </div>
                            `;

                            const markerElement = document.createElement('div');
                            markerElement.className = `event-marker event-marker-${markerColor}`;
                            markerElement.style.color = textColor;
                            markerElement.innerText = markerText;

                            const marker = new mapboxgl.Marker({element: markerElement})
                                .setLngLat([longitude, latitude])
                                .addTo(this.map);

                            const tooltip = new mapboxgl.Popup({closeButton: false, closeOnClick: false}).setHTML(tooltipContent);

                            markerElement.addEventListener('mouseenter', () => {
                                tooltip.addTo(this.map);
                                tooltip.setLngLat([longitude, latitude]);
                            });

                            markerElement.addEventListener('mouseleave', () => {
                                tooltip.remove();
                            });

                            if (!this.state.showEventMarkers) {
                                markerElement.style.display = 'none';
                            }

                            eventMarkers.push(marker);
                        });
                    });

                    this.setState({ eventMarkers: eventMarkers });
                }

                // Create speeding event markers
                if (data.speedingEvents) {
                    const speedingEventMarkers = [];
                    data.speedingEvents.forEach(evt => {
                        const speedConversionFactor = this.props.speedUnit === 0 ? 2.23694 : 3.6;
                        const roundingFactor = this.props.speedUnit === 0 ? 5 : 10;
                        const speed = evt.speed * speedConversionFactor;
                        const speedLimit = Math.round((evt.speedLimit * speedConversionFactor) / roundingFactor) * roundingFactor;

                        if (
                            this.props.speedUnit === 0 &&
                            speedLimit === 70 &&
                            speed >= 70 &&
                            speed <= 82
                        ) {
                            return;
                        }

                        if (
                            this.props.speedUnit === 1 &&
                            speedLimit === 110 &&
                            speed >= 110 &&
                            speed <= 132
                        ) {
                            return;
                        }
                        
                        let markerColor = 'black';
                        let textColor = 'white';
                        if (speed / speedLimit > 1.00 && speed / speedLimit <= 1.15) {
                            markerColor = 'yellow';
                            textColor = 'black';
                        } else if (speed / speedLimit > 1.15 && speed / speedLimit <= 1.20) {
                            markerColor = 'orange';
                            textColor = 'black';
                        } else if (speed / speedLimit > 1.20 && speed / speedLimit <= 1.25) {
                            markerColor = 'darkorange';
                            textColor = 'white';
                        } else if (speed / speedLimit > 1.25) {
                            markerColor = 'red';
                            textColor = 'white';
                        }

                        const el = document.createElement('div');
                        el.className = `event-marker event-marker-${markerColor}`;
                        el.style.color = textColor;
                        el.innerText = `${speed.toFixed(0)}`;
                        el.style.width = '25px';
                        el.style.height = '25px';

                        const marker = new mapboxgl.Marker(el)
                            .setLngLat([evt.position.longitude, evt.position.latitude])
                            .addTo(this.map);

                        const eventTime = new Date(evt.eventDateTime).toLocaleTimeString('en-GB', {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                        });

                        const tooltipContent = `
                            <div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
                                <p style="margin: 0"><strong>Event Time:</strong> ${eventTime}</p>
                                <p style="margin: 0"><strong>Speed:</strong> ${speed.toFixed(1)} ${this.props.speedUnit === 0 ? 'mph' : 'kmh'}</p>
                                <p style="margin: 0"><strong>Speed Limit:</strong> ${speedLimit.toFixed(0)} ${this.props.speedUnit === 0 ? 'mph' : 'kmh'}</p>
                            </div>
                        `;
                        const tooltip = new mapboxgl.Popup({closeButton: false, closeOnClick: false}).setHTML(tooltipContent);

                        el.addEventListener('mouseenter', () => {
                            tooltip.addTo(this.map);
                            tooltip.setLngLat([evt.position.longitude, evt.position.latitude]);
                        });

                        el.addEventListener('mouseleave', () => {
                            tooltip.remove();
                        });

                        if (!this.state.showSpeedingEventMarkers) {
                            el.style.display = 'none';
                        }

                        speedingEventMarkers.push(marker);
                    });

                    this.setState({ speedingEventMarkers: speedingEventMarkers });
                }

            })
            .catch((error) => {
                console.error('Error fetching journey details:', error);
            });
    }

    addJourneyPathToMap(debugPositions) {
        const lineString = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: debugPositions.map((position) => [position.longitude, position.latitude]),
            },
        };

        const sourceId = 'journey';

        if (this.map.getSource(sourceId)) {
            this.map.getSource(sourceId).setData({
                type: 'FeatureCollection',
                features: [lineString],
            });
        } else {
            this.map.addSource(sourceId, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [lineString],
                },
            });
        }

        if (this.map.getLayer('journey-line')) {
            this.map.removeLayer('journey-line');
        }

        this.map.addLayer({
            id: 'journey-line',
            type: 'line',
            source: sourceId,
            paint: {
                'line-color': 'rgba(44,127,246,0.85)',
                'line-width': 5,
            },
        });

        this.state.debugMarkers.forEach((marker) => marker.remove());
        this.setState({ debugMarkers: [] });

        this.state.startEndMarkers.forEach((marker) => marker.remove());
        this.setState({ startEndMarkers: [] });

        if (debugPositions.length > 0) {
            const startPosition = debugPositions[0];
            const startMarkerElement = document.createElement('div');
            startMarkerElement.className = 'start-marker';

            const startMarker = new mapboxgl.Marker({ element: startMarkerElement })
                .setLngLat([startPosition.longitude, startPosition.latitude])
                .addTo(this.map);

            const endPosition = debugPositions[debugPositions.length - 1];
            const endMarkerElement = document.createElement('div');
            endMarkerElement.className = 'end-marker';

            const endMarker = new mapboxgl.Marker({ element: endMarkerElement })
                .setLngLat([endPosition.longitude, endPosition.latitude])
                .addTo(this.map);

            this.setState((prevState) => ({
                startEndMarkers: [...prevState.startEndMarkers, startMarker, endMarker],
            }));
        }

        const coordinates = debugPositions.map((position) => [position.longitude, position.latitude]);
        const bounds = coordinates.reduce(
            (acc, coord) => acc.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );

        const screenWidth = window.innerWidth;
        if (screenWidth <= 1000) {
            this.map.fitBounds(bounds, {
                padding: 10,
                maxZoom: 11,
            });
        } else {
            this.map.fitBounds(bounds, {
                padding: 200,
                maxZoom: 15,
            });
        }
    }

    loadMoreJourneys() {
        const { pageNumber, numberOfPages, canLoadMore } = this.state;
        if (canLoadMore && pageNumber < numberOfPages) {
            const nextPageNumber = pageNumber + 1;
            this.setState({ pageNumber: nextPageNumber }, () => {
                this.getJourneys(this.state.pageSize, this.state.pageNumber);
            });
        }
    }

    getJourneys(pageSize, pageNumber) {
        return new Promise((resolve, reject) => {
            let urlPath = `/api/Journey/journeys?VehicleReference=${this.state.vehicleReference}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&ShowPhantom=${this.state.showPhantom}`;
            if (this.state.startDate && this.state.endDate) {
                urlPath += `&StartDate=${this.state.startDate.toISOString()}&EndDate=${this.state.endDate.toISOString()}`;
            }
            fetch(urlPath, GetHttpConfig(this.props.userAccessToken))
                .then((response) => response.json())
                .then((data) => {
                    const journeyData = data.results;
                    const canLoadMore = data.pageDetail.hasMoreData;

                    this.setState(
                        (prevState) => ({
                            journeyData: [...prevState.journeyData, ...journeyData],
                            loading: false,
                            error: false,
                            totalResults: data.pageDetail.totalResults,
                            numberOfPages: data.pageDetail.numberOfPages,
                            pageNumber: data.pageDetail.pageNumber,
                            canLoadMore: canLoadMore,
                        }),
                        () => {
                            if (this.state.journeyData.length > 0 && !this.state.activeJourney) {
                                this.fetchJourneyDetails(this.state.journeyData[0].journeyId);
                                this.setState({ activeJourney: this.state.journeyData[0].journeyId });
                            }
                        }
                    );

                    resolve();
                })
                .catch((error) => {
                    this.setState({
                        journeyData: [],
                        loading: false,
                        error: true,
                        totalResults: 0,
                        numberOfPages: 1,
                        pageNumber: 1,
                    });

                    reject(error);
                });
        });
    }

    searchForJourneys() {
        this.setState({
            loading: true,
            journeyData: [],
            totalResults: null,
            numberOfPages: null,
            pageNumber: 1,
            canLoadMore: true,
        });
        this.getJourneys(this.state.pageSize, this.state.pageNumber);
    }

    clearSearch() {
        this.setState({
            startDate: null,
            endDate: null,
            loading: true,
            journeyData: [],
            totalResults: null,
            numberOfPages: null,
            pageNumber: 1,
            canLoadMore: true,
        });
        this.getJourneys(this.state.pageSize, this.state.pageNumber);
    }

    toggleSearchContent = () => {
        this.setState((prevState) => ({
            showSearchContent: !prevState.showSearchContent,
        }));
    };

    toggleGPSMarkers = () => {
        this.setState({ showMarkers: !this.state.showMarkers });
    };

    toggleEventMarkers = () => {
        this.setState((prevState) => ({
            showEventMarkers: !prevState.showEventMarkers
        }));
    };

    toggleSpeedingEventMarkers = () => {
        this.setState((prevState) => ({
            showSpeedingEventMarkers: !prevState.showSpeedingEventMarkers
        }));
    };

    render() {
        const { journeyData, loading, error, activeJourney, showSearchContent } = this.state;

        const statusMapping = {
            1: 'Tamper',
            2: 'Start cal',
            3: 'End cal',
            4: 'Phantom',
        };

        journeyData.forEach(function (journey) {
            if (journey.journeyScores.length === 0) {
                for (var x = 1; x < 9; x++) {
                    journey.journeyScores.push({ scoreType: x, score: 0 });
                }
            }
        });

        let journeyScoresContent = null;
        const desiredScoreTypes = [1, 2, 3, 4, 5];
        if (activeJourney) {
            const activeJourneyData = journeyData.find((journey) => journey.journeyId === activeJourney);
            if (activeJourneyData) {
                const journeyScores = activeJourneyData.journeyScores;

                journeyScoresContent = (
                    <div>
                        <div
                            style={{
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: 'white',
                            }}
                        >
                            {journeyScores
                                .filter((score) => desiredScoreTypes.includes(score.scoreType))
                                .map((score) => {
                                    let scoreColor;
                                    if (score.score >= 80) {
                                        scoreColor = 'green';
                                    } else if (score.score >= 50) {
                                        scoreColor = 'orange';
                                    } else {
                                        scoreColor = 'red';
                                    }

                                    let scoreTypeName;
                                    switch (score.scoreType) {
                                        case 1:
                                            scoreTypeName = 'Acceleration';
                                            break;
                                        case 2:
                                            scoreTypeName = 'Braking';
                                            break;
                                        case 3:
                                            scoreTypeName = 'Cornering';
                                            break;
                                        case 4:
                                            scoreTypeName = 'Speeding';
                                            break;
                                        case 5:
                                            scoreTypeName = 'Manoeuvre';
                                            break;
                                        default:
                                            scoreTypeName = '';
                                    }

                                    return (
                                        <p
                                            key={score.scoreType}
                                            style={{
                                                margin: '0',
                                                flexBasis: '20%',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: scoreColor,
                                                    fontSize: 'x-large',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {score.score.toFixed(2)}
                                            </span>
                                            <br /> {scoreTypeName}
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                );
            }
        }

        let content = null;

        if (loading && journeyData.length === 0) {
            content = <Loading />;
        } else if (error) {
            content = <p>There was an error.</p>;
        } else if (journeyData.length === 0) {
            content = <p>No journeys reported for unit.</p>;
        } else {
            const filteredJourneys = this.state.showPhantom
                ? journeyData
                : journeyData.filter((journey) => journey.distance !== 0);

            content = (
                <div className="table-responsive">
                    <table className="table table-striped table-dark">
                        <thead>
                        <tr>
                            <th>When</th>
                            <th>Date</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Received DateTime</th>
                            <th>When Received</th>
                            <th>Duration</th>
                            <th>Distance</th>
                            <th>Score</th>
                            <th>Status</th>
                            <th>Telemetry %</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredJourneys.map((journey) => (
                            <tr
                                key={journey.journeyId}
                                onClick={() => this.handleTableRowClick(journey.journeyId)}
                                className={
                                    this.state.activeJourney === journey.journeyId ? 'active' : ''
                                }
                            >
                                <td>
                                    <Moment fromNow>{journey.startDateTime}</Moment>
                                </td>
                                <td>
                                    <Moment format="DD-MM-YYYY">{journey.startDateTime}</Moment>
                                </td>
                                <td>
                                    <Moment format="HH:mm:ss">{journey.startDateTime}</Moment>
                                </td>
                                <td>
                                    <Moment format="HH:mm:ss">{journey.endDateTime}</Moment>
                                </td>
                                <td>
                                    <Moment format="DD-MM-YYYY HH:mm:ss">
                                        {journey.receivedDateTime}
                                    </Moment>
                                </td>
                                <td>
                                    <Moment fromNow>{journey.receivedDateTime}</Moment>
                                </td>
                                <td>{journey.duration.toFixed(0)} mins</td>
                                <td>
                                    {this.props.speedUnit === 0
                                        ? `${(journey.distance * 0.621371).toFixed(2)} miles`
                                        : `${journey.distance.toFixed(2)} kms`}
                                </td>
                                <td>
                                    {Math.round(GetScoreByType(5, journey.journeyScores))} / 100
                                </td>
                                <td>
                                    {journey.journeyStatus
                                        ? journey.journeyStatus
                                            .map(
                                                (statusObj) =>
                                                    statusMapping[statusObj.status] || statusObj.status
                                            )
                                            .join(', ')
                                        : 'N/A'}
                                </td>
                                <td>{journey.telemetryPercentage}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} className="fixed-col2">
                            <div
                                className="contentvehicle3"
                                style={{ gridColumn: '1 / -1', textAlign: 'center' }}
                            >
                                <h5>DEBUG!!! Vehicle: {this.props.params.vehiclereference}</h5>
                            </div>
                        </Col>
                        <Col md={2} lg={2} className="backbuttonlogoholder-journeylist">
                            <BackButtonLogoHolder
                                backlink={'/deviceOverview/' + this.state.vehicleReference}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} lg={8}>
                            <div className="contentjourneylist">
                                <h4>Journeys</h4>
                                <Button
                                    variant={'secondary'}
                                    onClick={this.toggleSearchContent}
                                    style={{ marginBottom: '10px' }}
                                >
                                    {showSearchContent ? 'Hide Search' : 'Show Search'}
                                </Button>
                                <Button
                                    variant={this.state.showPhantom ? 'success' : 'secondary'}
                                    style={{ marginLeft: '10px', flex: '1', marginBottom: '10px' }}
                                    onClick={this.toggleIsPhantom}
                                >
                                    {'Include Invalid Journeys'}
                                </Button>
                                <input
                                    type="number"
                                    value={this.state.tempNumberValue}
                                    onChange={this.handleInputChange}
                                    style={{marginRight:'10px'}}
                                />
                                <Button onClick={this.handleButtonClick} variant="primary">Update Number</Button>
                                <p>Current number: {this.state.numberValue}</p>
                                {showSearchContent && (
                                    <div className="contentsearch">
                                        <h4>Search</h4>
                                        <Form.Group>
                                            <p>Start Date:</p>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={(date) => this.setState({ startDate: date })}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                isClearable
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />

                                            <p>End Date:</p>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={(date) => this.setState({ endDate: date })}
                                                selectsEnd
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                minDate={this.state.startDate}
                                                isClearable
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />
                                        </Form.Group>

                                        <Button variant="primary" onClick={() => this.searchForJourneys()}>
                                            Search
                                        </Button>{' '}
                                        <Button variant="secondary" onClick={() => this.clearSearch()}>
                                            Clear
                                        </Button>
                                    </div>
                                )}
                                {content}
                                <div ref={this.observerRef} style={{ height: '1px' }}><Loading /></div>
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="fixed-col-journeylist">
                            {journeyScoresContent && (
                                <div style={{ margin: '0px 0' }}>{journeyScoresContent}</div>
                            )}
                            <div className="fixed-col">
                                <div style={{ display: 'flex', marginBottom: '0px' }}>
                                    <Button
                                        style={{ flex: '1' }}
                                        variant={this.state.showMarkers ? 'success' : 'danger'}
                                        onClick={this.toggleGPSMarkers}
                                    >
                                        {'GPS'}
                                    </Button>
                                    <Button
                                        style={{ flex: '1' }}
                                        variant={this.state.showEventMarkers ? 'success' : 'danger'}
                                        onClick={this.toggleEventMarkers}
                                    >
                                        {'Events'}
                                    </Button>
                                    <Button
                                        style={{ flex: '1' }}
                                        variant={this.state.showSpeedingEventMarkers ? 'success' : 'danger'}
                                        onClick={this.toggleSpeedingEventMarkers}
                                    >
                                        {'Speeding'}
                                    </Button>
                                </div>

                                <div
                                    id="mapContainer"
                                    ref={(el) => (this.mapContainer = el)}
                                    style={{ width: '100%', height: '100%' }}
                                ></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
